.skill {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 50px auto 0 auto;
  text-align: center;
  border: 1px solid rgb(168, 168, 168);
  border-radius: 5%;
  z-index: 2;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &::after {
    content: 'Meine Kenntnisse';
    cursor: pointer;
    position: absolute;
    bottom: 130px;
    background-color: white;
    color: rgb(38, 165, 19);
    font-size: 20px;
    font-weight: bold;
    transition: opacity 0.5s ease, bottom 0.5s ease;
    border: 1px var(--bs-secondary-color) solid;
    border-radius: 10px;
    padding: 0.5rem;
    z-index: -1;
    &:active {
      transform: scale(0.9);
    }
  }
  &:hover {
    transform: scale(1.05);

    &::after {
      bottom: -40px;
    }
  }
}
img {
  width: 256px;
  height: 256px;
}
.experience {
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1rem;
}

.experience:hover::before {
  opacity: 1;
}

.experience::before {
  content: var(--experience-content, '');
  opacity: 0;
  transition: opacity 0.5s ease;
  position: relative;
  right: 10px;
}

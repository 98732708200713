.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}
.popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid black;
  max-width: 500px;
  max-height: 75%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: 11;
  text-align: start;
  scrollbar-width: none;
  padding: 2%;
}
li {
  font-weight: bold;
}
.closePopup {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: bolder;
}

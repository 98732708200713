body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tech_disc {
  font-size: 12px;
  color: purple;
  visibility: hidden;
  transition: visibility 0.5s ease-in-out;
  &::before {
    content: var(--before-content, '');
    color: rgb(46, 1, 54);
    right: 10px;

    transition: opacity 0.5s ease;
    position: relative;
  }
}
.navbar {
  transform: translateY(-100%);
  transition: 0.5s ease-in-out;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-backdrop-filter: blur(30px);
}
.navbar-brand {
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.hi {
  font-size: 8rem;
  transition: transform 0.5s linear;
  animation: wave-animation 2s linear infinite;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg) translate(40px, 0px);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-15deg) translate(-40px, 0px);
  }
  100% {
    transform: rotate(0deg);
  }
}
.lookUp {
  font-size: 8rem;
  transition: transform 0.5s linear;
  animation: lookUp-animation 2s linear infinite;
}
@keyframes lookUp-animation {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.arrow .top {
  transform: rotate(220deg);
}
.arrow .bottom {
  transform: rotate(140deg);
}
.heroSectionPhoto {
  display: flex;
  align-items: center;
  justify-content: center;
}
#root {
  display: none;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.heroSection {
  position: relative;
  padding: 5% 5%;
  width: 100%;
  min-height: 400px;
  background-color: rgba(255, 217, 234, 0.1);
  top: calc((50vh - 50%) - 56px);
  margin-top: 56px;
  display: flex;
  align-items: center;
  transition: top 1s ease-in-out;
}
@media (max-width: 768px) {
  .heroSection {
    flex-direction: column-reverse;
    align-items: center;
  }
  .heroSectionText {
    width: 100% !important;
    min-width: 330px !important;
  }
}
.heroSectionText {
  width: 60%;
  min-width: 495px;
  height: 100%;

  text-align: start;
}
.myName {
  margin-bottom: 2rem;
  font-size: 3rem;
}

footer {
  background-color: rgba(209, 255, 242, 0.3);
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  padding: 0 5%;
  display: flex;
  align-items: center;
}
.contacts {
  position: relative;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  display: none;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 200px;
  padding: 36px;
  background-color: rgba(255, 217, 234, 0.3);
}

.contactLinks {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contactLink > svg:hover {
  transition: 0.3s ease;
  transform: scale(1.1);
}

.modalOverlay {
  position: absolute;
  width: 100vw;
  height: 100vh;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.iframe {
  width: 80vw;
  height: 60vh;
  border: none;
  position: absolute;
  top: 50%;
}
.sitePreviewImg {
  width: 100%;
  height: auto;
  border: 1px solid rgb(168, 168, 168);
  border-radius: 15px;
  margin-bottom: 15px;
}
.sitePreviewContainer {
  position: relative;
  background-color: white;
  padding: 15px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  width: 300px;
  border: 1px solid rgb(168, 168, 168);
  border-radius: 15px;
  transition: transform 0.3s ease;
  margin: 0 auto;
  &:hover {
    transform: scale(1.03);
  }
}
.gitButton {
  position: relative;
  top: 0;
  left: 0;
  color: white;
  background-color: black;
  border-radius: 10px;
  padding: 10px;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.03);
  }
}
.sitePreviewLink {
  position: relative;
  bottom: 0;
}

.downloadsSection {
  height: 200px;
  padding: 3rem;
  background-color: rgba(209, 255, 242, 0.3);
  position: relative;
}

.documentsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 768px) {
  .documentsContainer {
    flex-direction: column;
  }
}
.documentsName {
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-size: 20px;
  transition: color 0.5s ease;
  cursor: pointer;
}
.documentsName:hover {
  color: blueviolet;
}
.tech_disc {
  font-size: 12px;
  color: purple;
  position: absolute;
  transition: visibility 0.5s ease-in-out;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  font-weight: bolder;
  &::before {
    content: var(--before-content, '');
    color: rgb(255, 0, 0);
    right: 10px;

    transition: opacity 0.5s ease;
    position: relative;
  }
}

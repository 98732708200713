.skillsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  margin-top: 20px;
}
.skillsSection {
  padding: 3rem;
  position: relative;
  background-color: rgba(209, 255, 242, 0.2);
}
.tech_disc {
  font-size: 12px;
  color: purple;
  position: absolute;
  transition: visibility 0.5s ease-in-out;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  font-weight: bolder;
  &::before {
    content: var(--before-content, '');
    color: rgb(255, 0, 0);
    right: 10px;

    transition: opacity 0.5s ease;
    position: relative;
  }
}
